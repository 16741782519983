<template>
  <drop-down class="nav-item" :title="currentLanguage">
    <a v-for="(language, code) in languages" :key="code" class="dropdown-item" @click="changeLanguage(code)">
      {{ language }}
    </a>
  </drop-down>
</template>

<script>

import {DropDown} from "@/components/index";

export default {
  components: {DropDown},
  data() {
    return {
      languages: {
        en: 'English',
        es: 'Spanish'
      }
    };
  },
  computed: {
    currentLanguage() {
      return this.$i18n.locale;
    }
  },
  methods: {
    changeLanguage(code) {
      this.$i18n.locale = code;
    }
  }
};
</script>

<style scoped>
/* Add your custom styles here */
</style>
<script>
function debounce(func, wait, immediate) {
  let timeout;
  return function() {
    const context = this,
        args = arguments;
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      if (!immediate) func.apply(context, args);
    }, wait);
    if (immediate && !timeout) func.apply(context, args);
  };
}

export default {
  props: {
    videoId: {
      type: String,
      required: true
    },
    mute: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      styles: {},
      debounceTimeout: 6,
      videoSrc: '',
    };
  },
  methods: {
    handleScroll(scrollVal) {
      let oVal = scrollVal / 3;
      this.styles = {
        transform: `translate3d(0, ${oVal}px,0)`
      };
    },
    checkForParallax(scrollVal) {
      let fn = debounce(
          () => this.handleScroll(scrollVal),
          this.debounceTimeout
      );
      fn();
    }
  },
  mounted() {
    let self = this;

    this.videoSrc = `https://www.youtube.com/embed/${this.videoId}?autoplay=1&mute=${this.mute ? 1 : 0}&loop=1&color=white&controls=0&modestbranding=1&playsinline=1&rel=0&enablejsapi=1&playlist=${this.videoId}&showinfo=0&disablekb=1&vq=hd1080`;

    window.addEventListener('scroll', function() {
      let scrollVal = this.scrollY;
      self.checkForParallax(scrollVal);
    });
  }
};
</script>

<template>
  <div class="youtube-container">
    <iframe :src="this.videoSrc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
  </div>
</template>

<style scoped>
.video-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100vh; /* Adjust the height as needed */
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none; /* Ensures the video is non-interactive */
}

.youtube-container {
  overflow: hidden;
  width: 100%;
  /* Keep it the right aspect-ratio */
  aspect-ratio: 16/9;
  /* No clicking/hover effects */
  pointer-events: none;

  iframe {
    /* Extend it beyond the viewport... */
    width: 300%;
    height: 100%;
    /* ...and bring it back again */
    margin-left: -100%;
  }
}
</style>
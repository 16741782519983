<template>
  <navbar
    position="fixed"
    type="primary"
    :transparent="false"
    :color-on-scroll="colorOnScroll"
    menu-classes="ml-auto"
  >
    <template>
      <router-link v-popover:popover1 class="navbar-brand" to="/">
        <img src="/img/logos/VIENTO%20SUR.%20Isologotipo%20V2.png" alt="Logo" style="width: 100px; height: auto;" />
      </router-link>
    </template>
    <template slot="navbar-menu">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item">
          <a class="nav-link" href="#">{{ $t("sections.home.title") }}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#about-us">{{ $t("sections.about-us.title") }}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#projects">{{ $t("sections.projects.title") }}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#contact">{{ $t("sections.contact.title") }}</a>
        </li>
        <language-dropdown></language-dropdown>
      </ul>
    </template>
  </navbar>
</template>

<script>
import { Navbar } from '@/components';
import { Popover } from 'element-ui';
import LanguageDropdown from "@/components/LanguageDropdown.vue";
export default {
  name: 'main-navbar',
  props: {
    transparent: Boolean,
    colorOnScroll: Number
  },
  components: {
    Navbar,
    [Popover.name]: Popover,
    LanguageDropdown
  }
};
</script>

<style scoped></style>

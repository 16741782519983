<template>
  <div>
    <div class="clear-filter">
      <youtube-video-parallax
          class="page-header-video"
          video-id="vj2Gc3Wuia8"
      >
      </youtube-video-parallax>
    </div>
    <about-us></about-us>
    <projects></projects>
    <contact></contact>

    <video-modal
        :video-id="selectedVideoId"
        :isVisible="isModalVisible"
        @close-modal="closeModal"
        :mute="false"
    />
  </div>
</template>
<script setup>
import YoutubeVideoParallax from "@/pages/components/YoutubeVideoParallax.vue";
import AboutUs from "@/components/AboutUs.vue";
import Projects from "@/components/Projects.vue";
import Contact from "@/components/Contact.vue";
import VideoModal from "@/components/Projects/VideoModal.vue";
import {ref, provide } from "vue";

const isModalVisible = ref(false);
const selectedVideoId = ref(null);

function openModal(videoId) {
  selectedVideoId.value = videoId;
  isModalVisible.value = true;
}

function closeModal() {
  isModalVisible.value = false;
  selectedVideoId.value = null;
}

provide('openModal', openModal);
</script>
<style>
html {
  scroll-behavior: smooth !important;
}
</style>

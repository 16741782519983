<template>
  <div v-if="isVisible" class="modal-overlay" @click.self="closeModal">
    <div class="modal-content bg-primary">
      <div class="video-container">
        <youtube-video-parallax :video-id="videoId" :mute="mute"/>
      </div>
    </div>
  </div>
</template>

<script setup>
import YoutubeVideoParallax from "@/pages/components/YoutubeVideoParallax.vue";

const props = defineProps({
  videoId: String,
  isVisible: Boolean,
  mute: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(['close-modal']);

function closeModal() {
  emit('close-modal');
}
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  padding: 20px;
  border-radius: 10px;
  width: 75%; /* El modal tiene la mitad del ancho de la pantalla */
  max-width: 90%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.video-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* Proporción 16:9 (altura relativa al ancho) */
  height: 0;
}

.video-container > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
<script setup>
import GrowingCard from "@/components/GrowingCard.vue";
import { inject } from 'vue';
import SoundBars from "@/components/SoundBars.vue";

const openModal = inject('openModal');

function emit(videoId) {
  openModal(videoId);
}

function redirect(url) {
  window.open(url, '_blank');
}
</script>

<template>
  <div id="projects" class="section section-tabs">
    <div class="container">
      <div class="title">
        <h1>{{ $t("sections.projects.title") }}</h1>
      </div>
      <div class="row">
        <div class="col-lg-4 col-md-6 col-12">
          <growing-card src="/img/projects/project-1.jpg" video-id="63_FehPWxmA" :action="emit"></growing-card>
        </div>
        <div class="col-lg-4 col-md-6 col-12">
          <growing-card src="/img/projects/project-2.jpg" video-id="z2HFfflaaKc" :action="emit"></growing-card>
        </div>
        <div class="col-lg-4 col-md-6 col-12">
          <growing-card src="/img/projects/project-3.jpg" video-id="v_-sBTtYFQE" :action="emit"></growing-card>
        </div>
        <div class="col-lg-4 col-md-6 col-12">
          <growing-card src="/img/projects/project-4.jpg" video-id="1o6i1Th0zOo" :action="emit"></growing-card>
        </div>
        <div class="col-lg-4 col-md-6 col-12">
          <growing-card src="/img/projects/project-5.jpg" video-id="https://www.instagram.com/reel/C_-qTCUuRFU/" :action="redirect"></growing-card>
        </div>
        <div class="col-lg-4 col-md-6 col-12">
          <growing-card src="/img/projects/project-6.jpg" video-id="uw1YeAy6_Ns" :action="emit"></growing-card>
        </div>
      </div>
    </div>
    <!--<sound-bars width="200"/>
    <sound-bars position="bottom" width="200"/>-->
  </div>

</template>

<style scoped>
.section-tabs {
  position: relative;
  background: #C8D8E7;
}
</style>
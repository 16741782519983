var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:['transition-bars', _setup.props.position],style:({
    width: `${_setup.props.width}px`,
    left: '50%',
    transform: 'translateX(-50%)',
    [_setup.props.position]: '-1px'
  })},_vm._l((_setup.bars),function(bar,index){return _c('div',{key:index,staticClass:"bar",class:_setup.props.position === 'top' ? 'bar-top' : 'bar-bottom',style:({
      height: bar.height + 'px',
      width: _setup.barWidth + 'px',
      'background-color': _setup.props.color,
      'animation-delay': (index * 0.05) + 's'
    })})}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }
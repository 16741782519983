<script setup>
import { onMounted, ref } from "vue";

const props = defineProps({
  position: {
    type: String,
    default: 'top',
    validator: value => ['top', 'bottom'].includes(value),
  },
  color: {
    type: String,
    default: '#266497',
  },
  width: {
    type: Number,
    default: window.innerWidth,
  }
});

const bars = ref([]);
const barWidth = 10;

onMounted(() => {
  let numberOfBars = Math.floor(props.width / (barWidth * 2));
  for (let i = 0; i < numberOfBars; i++) {
    bars.value.push({ height: Math.floor(Math.random() * 40) + 10 });
  }
});
</script>

<template>
  <div
      :class="['transition-bars', props.position]"
      :style="{
      width: `${props.width}px`,
      left: '50%',
      transform: 'translateX(-50%)',
      [props.position]: '-1px'
    }"
  >
    <div
        v-for="(bar, index) in bars"
        :key="index"
        :style="{
        height: bar.height + 'px',
        width: barWidth + 'px',
        'background-color': props.color,
        'animation-delay': (index * 0.05) + 's'
      }"
        class="bar"
        :class="props.position === 'top' ? 'bar-top' : 'bar-bottom'"
    />
  </div>
</template>

<style scoped>
.transition-bars {
  position: absolute;
  display: flex;
  justify-content: space-evenly;
  pointer-events: none;
  z-index: 1;
  transform: translateX(-50%);
}

.bar {
  transition: height 1s ease;
  width: 10px;
  animation: wave 0.5s ease-in-out infinite;
}

.bar-bottom {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.bar-top {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

@keyframes wave {
  0%, 100% {
    transform: scaleY(1);
  }
  50% {
    transform: scaleY(1.1);
  }
}

.transition-bars.top .bar {
  transform-origin: top;
  align-self: flex-start;
}

.transition-bars.bottom .bar {
  transform-origin: bottom;
  align-self: flex-end;
}

.bar:hover {
  transform: scaleY(1.5);
  background-color: transparent;
}
</style>
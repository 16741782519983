<template>
  <footer
      class="footer bg-primary text-white"
      :class="{ [`footer-${type}`]: type }"
  >
    <div class="container">
      <nav class="nav-menu">
        <ul>
          <li>
            <a href="https://www.behance.net/ClaudioCao">
              Diseñado por Claudio Cao
            </a>
          </li>
        </ul>
      </nav>
      <div class="copyright">
        <social-links />
      </div>
    </div>
  </footer>
</template>

<script>
import SocialLinks from "@/layout/SocialLinks.vue";

export default {
  components: { SocialLinks },
  props: {
    backgroundColor: String,
    type: String,
  },
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>

<style scoped>
.footer {
  padding: 20px 0;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.nav-menu {
  display: flex;
}

.nav-menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.nav-menu ul li {
  display: inline-block;
  margin-right: 20px;
}

.copyright {
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-links {
  display: flex;
  gap: 20px;
}

/* Media Query para pantallas pequeñas (móviles) */
@media (max-width: 768px) {
  .nav-menu {
    display: none; /* Ocultar el nav en pantallas pequeñas */
  }

  .container {
    flex-direction: column;
    text-align: center;
  }

  .social-links {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 10px;
  }
}
</style>
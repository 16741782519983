<template>
  <div id="app">
    <router-view name="header" />
    <div class="wrapper">
      <router-view />
    </div>
    <router-view name="footer" />
  </div>
</template>
<script>
export default {};
</script>
<style>
@font-face {
  font-family: 'Satoshi';
  src: url('./assets/fonts/Satoshi-Regular.woff2') format('woff2'),
  url('./assets/fonts/Satoshi-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Satoshi';
  src: url('./assets/fonts/Satoshi-Bold.woff2') format('woff2'),
  url('./assets/fonts/Satoshi-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

:root {
  --primary-color: #1F2B3B !important;
  --info-color: #C6A72A !important;
}

body {
  font-family: 'Satoshi', sans-serif !important;
  //color: var(--primary-color) !important; /*color de la tipografía*/

  .bg-primary {
    background-color: var(--primary-color) !important;
  }

  .bg-info {
    background-color: var(--info-color) !important;
  }

  .btn-primary {
    background-color: var(--primary-color) !important;
  }

  .btn-info {
    background-color: var(--info-color) !important;
  }
}



</style>